import React, { useEffect, useRef } from "react";
import axios from "axios";
import "date-fns";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import { WithContext as ReactTags } from 'react-tag-input';
import "./Form.css";
import Modal from "@material-ui/core/Modal";
import bannerImage from "../assets/bannerImage.png";
import success from "../assets/success.png";
import close from "../assets/close.png";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RegisterBanner from "./RegisterBanner.js";
import Header from "./Header.js";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import deleter from "../assets/delete.png";

class Demo extends React.Component {
  render() {
    return <p onClick={this.props.click}>This is Demo. Click me.</p>;
  }
}
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const recaptchaRef = React.createRef();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",
    // width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

const Accordion = withStyles({
  root: {
    //   border: '1px solid rgba(0, 0, 0, .125)',
    margin: "2em",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "2em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    color: "#00AFB9",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      //   boxShadow: "2px 0 15px -4px rgba(0, 0, 0, 0.25), -12px 0 22px -4px rgba(0, 0, 0, 0.25)",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Form() {
  // const clone = React.cloneElement(<NewAccordion />, {
  //   click: () => {
  //     alert("You clicked Demo :)");
  //   }
  // });

  const myRef = useRef(null);
  const myRef1 = useRef(null);

  const classes = useStyles();
  const history = useHistory();

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [facility, setFacility] = React.useState([]);

  useEffect(() => {
    if (localStorage.getItem("cookie") !== "superadmin") {
      history.push("/");
    }
    getFacility();
  }, []);

  const getFacility = () => {
    // https://staging-api.hummingbird.health/api
    // http://ec2-54-211-237-113.compute-1.amazonaws.com:8092/api/web/lab/list
    // https://api.hummingbird.health/api/
    axios
      .get(
        "https://api.hummingbird.health/api/web/lab/list"
        // ,
        // {
        //   headers: {
        //     Authorization:
        //       "66199319-c32f-48aa-ae17-dbb92bd30b35c82c6a09-e5fd-4ab5-a1e7-2cbebec3e4da",
        //   },
        // }
      )
      .then((res) => {
        //  console.log(res.data.info)
        setFacility(res.data.info);
      });
  };

  //
  const [facilitySelect, setfacilitySelect] = React.useState("");
  const [labIdSelect, setlabIdSelect] = React.useState("");
  const [facilitySelectedList, setFacilityData] = React.useState([])
  const handleChangeFacility = (event) => {
    // setfacilitySelect(event.target.value);
    // console.log("setfacilitySelect",event.target.value.split(",")[0])
    // console.log("labIdSelect",event.target.value.split(",")[1])

    setfacilitySelect(event.target.value.split(",")[0]);
    setlabIdSelect(event.target.value.split(",")[1]);
  };

  //Panel state
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    console.log('Expanded -> ', panel)
    console.log('New Expanded -> ', newExpanded)
    setExpanded(newExpanded ? panel : false);
    console.log('Expanded Changes -> ', expanded)
  };

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const body = (
    <div style={modalStyle} className="paperModal">
      <div className="closeHolder" onClick={handleClose}>
        <img src={close} />
      </div>
      <div className="content">
        <div className="rander">
          <img src={bannerImage} />
        </div>
        <div className="center">
          <img src={success} />
        </div>
      </div>

      <div className="successmsg">
        <h1>Thank you for your user information!</h1>
      </div>
    </div>
  );

  const [counts, setCounts] = React.useState(0);
  const [voted, setVotes] = React.useState();


  const [clone, getclone] = React.useState([]);

  const [stateChecked, setStateCheck] = React.useState({
    Lab_inventory_manager: false,
    Lab_Manager: false,
    Lab_Assistant: false,
    Lab_results_Recorder: false,
  });

  const handleChangeCheck = (event, index, nameValue) => {
    console.log("correctItem", event.target.checked);
    if (event.target.checked == true) {
      formArray[index].permissions.push(nameValue)
    }
    if (event.target.checked == false) {
      let arrayCheck = formArray[index].permissions
      formArray[index].permissions = []
      arrayCheck = arrayCheck.filter(x => {
        if (x != nameValue) {

        }
      })
      if (arrayCheck.length > 0) {
        arrayCheck.map(x => {
          formArray[index].permissions.push(x)
        })
      }
    }
    // formArray[index].permissions.push(event.target.value)
    // setStateCheck((prevState) => {
    //   return { ...prevState, [event.target.name]: event.target.checked };
    // });
  };

  // mobile number
  const [newnumber, setNumber] = React.useState("");

  const mobileNumberChange = (number, index) => {
    // setNumber(number);
    formArray[index].mobileNumber = number
  };

  // firstname
  const [firstName, setFirstName] = React.useState("");

  const handleChangeForm1 = (event, index) => {
    console.log('FirstName Index -> ', index)
    console.log('FirstName Event -> ', event)
    // setFirstName(event.target.value);
    // console.log("firstnamechange", firstName);

    // setFirstName((prevState) => {
    //   return { ...prevState, [event.target.name]: event.target.value };

    // });
  };

  // lastname
  const [lastName, setLastName] = React.useState("");

  const handleChangeForm2 = (event, index) => {
    formArray[index].lastName = event.target.value
    // setLastName(event.target.value);
    // console.log("lastnamechange", lastName);
  };

  // email
  const [email, setEmail] = React.useState("");

  const handleChangeForm3 = (event, index) => {
    formArray[index].email = event.target.value
    // setEmail(event.target.value);
    // console.log("emailnamechange", email);
  };

  // udid
  const [udid, setUdid] = React.useState("");

  const handleChangeForm4 = (event, index) => {
    formArray[index].udid = event.target.value
    // setUdid(event.target.value);
    // console.log("udidnamechange", udid);
  };

  let [formArray, setFormArray] = React.useState([{
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    udid: '',
    facilityName:'',
    // permissions: null,

    permissions: [],
  }]);

  React.useEffect(() => {
    // let obj = {
    //   firstName: '',
    //   lastName: '',
    //   mobileNumber: '',
    //   email: '',
    //   udid: '',
    //   // permissions: null,

    //   permissions: [],
    // }
    // formArray.push(obj)
  }, [counts, stateChecked]);

  //Error States
  const [selecteerror, setselecteerror] = React.useState("");
  const [errorFirstData, updateFirstErrorData] = React.useState();
  const [errorLastData, updateLastErrorData] = React.useState();
  const [errorMobileData, updateMobileErrorData] = React.useState();
  const [errorEmailData, updateEmailErrorData] = React.useState();
  const [errorStateChecked, updateStateChecked] = React.useState();


  const addUserOne = () => {
    // console.log('Suuuu -> ',formArray)

    let arrFlag = 0
    let newLength = formArray.length
    setExpanded("panel" + (newLength + 2));
    formArray.map((x, index) => {
      if (index == newLength - 1) {
        if (x.firstName == '') {
          arrFlag = 1
          toast.error("Please enter first name");
          myRef.current.scrollIntoView();
        }
        else if (x.lastName == '') {
          arrFlag = 1
          toast.error("Please enter last name");
          myRef.current.scrollIntoView();
        }
        else if (x.mobileNumber == '') {
          arrFlag = 1
          toast.error("Please enter mobile number");
          myRef.current.scrollIntoView();
        }
        else if (x.mobileNumber.length !== 11) {
          arrFlag = 1
          toast.error("Please enter a valid mobile number");
          myRef.current.scrollIntoView();
        }
        else if (x.email == '') {
          arrFlag = 1
          toast.error("Please enter email");
          myRef.current.scrollIntoView();
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(x.email)) {
          arrFlag = 1
          toast.error("Please enter a valid email id");
          myRef1.current.scrollIntoView();
        }
        else if(x.facilityName == ''){
          arrFlag = 1
          toast.error("Please select a facility name");
          myRef.current.scrollIntoView();
        }
        else if (x.permissions.length == 0) {
          arrFlag = 1
          toast.error("Please select a role");
          myRef.current.scrollIntoView();
        }
      }
    })
    if (arrFlag == 0) {
      let arrayList = formArray

      setFormArray([])
      formArray = []

      arrayList.map(x => {
        formArray.push(x)
      })
      console.log('Suuuusss -> ', formArray)
      // setFormArray(formArray)
      let obj = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        udid: '',
        facilityName:'',
        // permissions: null,

        permissions: [],
      }
      formArray.push(obj)
      setFormArray(formArray)
    }

    // console.log('Suuuu -> ',formArray)
  }
  const handelChangeNewFirst = (e, index) => {
    // console.log('DFFFDDDDD -> ', formArray)
    // console.log('jkdfkjasdfkjsa -> ', e)
    formArray[index].firstName = event.target.value
    // setFirstName(e.target.value)
  }
  const handleChangeFacilityNew = (e, index) => {
    formArray[index].facilityName = e.target.value
  }
  const deleteUser = (e, index) => {
    let arrayList = formArray
    setFormArray([])
    formArray = []
    arrayList.map((x, ind) => {
      if (index == ind) {

      }
      else {
        formArray.push(x)
      }
    })
    setFormArray(formArray)
  }
  const submitFormData = () => {
    let newLength = formArray.length
    let arrFlag = 0
    if (facilitySelectedList.length == 0) {
      arrFlag = 1
      toast.error("Please select Facility");
      myRef.current.scrollIntoView();
    }
    else {
      formArray.map((x, index) => {
        if (index == newLength - 1) {
          if (x.firstName == '') {
            arrFlag = 1
            toast.error("Please enter first name");
            myRef.current.scrollIntoView();
          }
          else if (x.lastName == '') {
            arrFlag = 1
            toast.error("Please enter last name");
            myRef.current.scrollIntoView();
          }
          else if (x.mobileNumber == '') {
            arrFlag = 1
            toast.error("Please enter mobile number");
            myRef.current.scrollIntoView();
          }
          else if (x.mobileNumber.length !== 11) {
            arrFlag = 1
            toast.error("Please enter a valid mobile number");
            myRef.current.scrollIntoView();
          }
          else if (x.email == '') {
            arrFlag = 1
            toast.error("Please enter email");
            myRef.current.scrollIntoView();
          }
          else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(x.email)) {
            arrFlag = 1
            toast.error("Please enter a valid email id");
            myRef1.current.scrollIntoView();
          }
          else if(x.facilityName == ''){
            arrFlag = 1
            toast.error("Please select a facility name");
            myRef.current.scrollIntoView();
          }
          else if (x.permissions.length == 0) {
            arrFlag = 1
            toast.error("Please select a role");
            myRef.current.scrollIntoView();
          }
        }
      })
    }

    if (arrFlag == 0) {
      let data = {
        // facilityName: facilitySelect,
        labId: labIdSelect,
        users: formArray,
      };
      //https://staging-api.hummingbird.health/api
      //http://ec2-54-211-237-113.compute-1.amazonaws.com:8092/api/web/user/signup
      //https://api.hummingbird.health/api/
      axios
        .post(
          "https://api.hummingbird.health/api/web/user/signup",
          data
        )
        .then((res) => {
          if (res.data.statusCode == 200 && res.data.status == "Success") {
            setOpen(true);
            setIsLoaded(false);
          }
        });
    }

  }

  const handleDelete = (i) => {
    console.log('Delete -> ', i)
    // const { tags } = this.state;
    // this.setState({
    //  tags: tags.filter((tag, index) => index !== i),
    // });
  }

  const handleAddition = (tag) => {
    console.log('Add -> ', tag)
    // this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  const handleChangeFacilityName = (event, index) => {

  }

  const addTags = event => {
    if (event.key === "Enter" && event.target.value !== "") {
      setFacilityData([...facilitySelectedList, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = index => {
    setFacilityData([...facilitySelectedList.filter(tag => facilitySelectedList.indexOf(tag) !== index)]);
  };
  return (
    <>
      <ToastContainer autoClose={2000} />
      <Header />
      <RegisterBanner />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {isLoaded == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
          <div className="accordionHolder">
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="mainer"
            >
              <div className="accordionbg">
                <div className="accSummary">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography ref={myRef}>
                      Facility Information
                    <FormHelperText style={{ color: "red" }}>
                      </FormHelperText>
                    </Typography>
                  </AccordionSummary>
                </div>
              </div>
              <AccordionDetails>
                <Typography style={{ width: "100%" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} sm={12}>
                      <div style={{fontSize:'12px',color:'black',marginLeft:'12.5px',fontWeight:'700'}}>
                      Note: Please enter each testing site. You can add multiple sites by pressing the 'Enter' key."
                      </div>
                      <div >
                        <TextField
                          id="standard-basic1"
                          label="Facility Name"
                          // autoComplete="new-password"
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          required
                          onKeyUp={event => addTags(event)}
                          name="lastName"
                          inputProps={{ maxLength: 40 }}
                        />
                      </div>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%", display: 'inline-flex', marginTop: '4%',flexFlow:'wrap' }}
                      >
                        {facilitySelectedList.map((tag, index) => (
                          <div style={{
                            marginLeft: '1%',
                            marginRight: '1%',
                            backgroundColor: "#6fe2e9",
                            padding: '1%',
                            borderRadius: '8px', paddingLeft: '2%', paddingRight: '2%',margin:'1%'
                          }}><span style={{borderRadius:'50px',borderColor:'#fff',cursor:'pointer'}} onClick={() => removeTags(index)} >x </span>{tag}</div>
                        ))}
                      </FormControl>

                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {formArray.map((x, index) => (
              <Accordion
                square
                expanded={expanded == "panel" + (index + 2)}
                onChange={handleChange("panel" + (index + 2))}
                index={index}
                id={"button_" + index}
              >
                <div className="accordionbg">
                  <div className="accSummary">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                    >
                      <Typography ref={myRef1}>
                        User Information
                    <FormHelperText style={{ color: "red" }}>
                        </FormHelperText>
                      </Typography>
                    </AccordionSummary>
                  </div>
                </div>

                <AccordionDetails>
                  <Typography style={{ width: "100%" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} sm={12}>
                        <TextField
                          id="standard-basic1"
                          label="First Name "
                          autoComplete="new-password"
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          required
                          onChange={(event) => { handelChangeNewFirst(event, index) }}
                          name="firstName"

                          inputProps={{ maxLength: 40 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} sm={12}>
                        <TextField
                          id="standard-basic1"
                          label="Last Name "
                          autoComplete="new-password"
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          required
                          onChange={(event) => { handleChangeForm2(event, index) }}
                          name="lastName"

                          inputProps={{ maxLength: 40 }}
                        />
                      </Grid>
                      <div style={{fontSize:'12px',color:'black',marginLeft:'12.5px',fontWeight:'700'}}>
                      Note: Please enter the mobile number and email where the app password will be sent for login.
                      </div>
                      <Grid item xs={12} md={6} sm={12}>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ paddingBottom: "0.3em" }}
                        >
                          Mobile Number*
                    </InputLabel>
                        <PhoneInput
                          country={"us"}
                          placeholder={"hello"}
                          disableDropdown={true}
                          onChange={(event) => { mobileNumberChange(event, index) }}

                        />
                      </Grid>

                      <Grid item xs={12} md={6} sm={12}>
                        <TextField
                          id="standard-basic4"
                          label="Email"
                          InputProps={{
                            className: classes.input,
                          }}
                          className={classes.textField}
                          required
                          onChange={(event) => { handleChangeForm3(event, index) }}
                          name="email"
                          autoComplete="new-password"

                          inputProps={{ maxLength: 150 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={12}>
                        <InputLabel id="demo-simple-select-label">
                          Facility Name
                      </InputLabel>
                        <Select
                        className={classes.textField}
                        required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={racedSelect}
                          onChange={(event) => {handleChangeFacilityNew(event,index)}}
                          name="facilityName"
                        >
                          {facilitySelectedList.map((index) => (
                            <MenuItem
                              value={index}
                            >
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormLabel component="legend" className="paddingAdj">
                            Role (permissions) *
                      </FormLabel>
                          <FormGroup>
                            <Grid item xs={12} md={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // checked={stateChecked.Shortness_of_breath}
                                    // onChange={handleChangeCheck}
                                    name="Lab_inventory_manager"
                                    onChange={(event) => { handleChangeCheck(event, index, 'Lab Inventory Manager') }}
                                    value="Lab inventory management - accepts shipments, logs inventory, requests supplies"
                                  />
                                }
                                label="Lab inventory management - accepts shipments, logs inventory, requests supplies"
                              />
                            </Grid>

                            <Grid item xs={12} md={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // checked={stateChecked.Cough}
                                    // onChange={handleChangeCheck}
                                    name="Lab_Manager"
                                    onChange={(event) => { handleChangeCheck(event, index, 'Lab Manager') }}
                                    value="Lab testing management - reviews and submits daily reports on the tests conducted and results"
                                  />
                                }
                                label="Lab testing management - reviews and submits daily reports on the tests conducted and results"
                              />
                            </Grid>

                            <Grid item xs={12} md={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // checked={stateChecked.Chills}
                                    // onChange={handleChangeCheck}
                                    name="Lab_Assistant"
                                    onChange={(event) => { handleChangeCheck(event, index, 'Lab Assistant') }}
                                    value="Lab testing - inputs patient information and collects test sample"
                                  />
                                }
                                label="Lab testing - inputs patient information and collects test sample"
                              />
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // checked={stateChecked.Fever}
                                    // onChange={handleChangeCheck}
                                    name="Lab_results_Recorder"
                                    onChange={(event) => { handleChangeCheck(event, index, 'Lab results Recorder') }}
                                    value="Lab results recording - submits test result information"
                                  />
                                }
                                label="Lab results recording - submits test result information"
                              />
                            </Grid>

                            <Grid item xs={12} md={12} sm={12}>
                              <div className="deviceInfo">
                                <h2>Device Information</h2>

                                <p>
                                  This is required for iOS devices. If users are
                                  using lab-provided devices, this information only
                                  needs to be submitted once for each lab site. Any
                              user can login on a registered device.{" "}
                                </p>

                                <p>How to find the UDID on iOS devices:</p>
                                <p>
                                  <a
                                    href="https://www.wikihow.com/Obtain-the-Identifier-Number-(UDID)-for-an-iPhone,-iPod-or-iPad"
                                    target="_blank"
                                  >
                                    https://www.wikihow.com/Obtain-the-Identifier-Number-(UDID)-for-an-iPhone,-iPod-or-iPad{" "}
                                  </a>
                                </p>
                              </div>
                            </Grid>

                            <Grid item xs={12} md={6} sm={12}>
                              <TextField
                                id="standard-basic4"
                                label="UDID - Device"
                                InputProps={{
                                  className: classes.input,
                                }}
                                className={classes.textField}
                                onChange={(event) => { handleChangeForm4(event, index) }}
                                name="udid"
                                autoComplete="new-password"

                                inputProps={{ maxLength: 150 }}
                              />
                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
                {index == formArray.length - 1 && index > 0 ? <div className="deleterHolder">

                  <img
                    src={deleter}
                    className="btnDeleter"
                    onClick={(event) => { deleteUser(event, index) }}
                  />


                  <span style={{
                    'position': 'absolute',
                    'top': '-2em',
                    'right': '-1.3em',
                    'color': 'crimson'
                  }}
                    onClick={(event) => { deleteUser(event, index) }}
                  >
                    Remove
                  </span>
                </div> : <div></div>}

              </Accordion>
            ))}


            {/* {clone} */}

            {/* <NewAccordion />

          <div className="cloned">
            {clone}
            </div> */}
            <div className="adder">
              <button className="btnAdder" onClick={addUserOne}>
                Add User +
            </button>
            </div>

            {/* <div className="captcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LcxHgsaAAAAAJgWo250QVPkr-vz5Qi0i5XOhdmh"
              onChange={onChange}
            />
          </div> */}

            {/* <FormHelperText
            style={{
              color: "red",
              textAlign: "center",
              padding: "1em 0",
              fontSize: "14px",
            }}
            ref={myRef1}
          >
            <span> {errorCaptcha ? errorCaptcha : ""}</span>
          </FormHelperText> */}

            <div className="submit">
              <button className="btn" onClick={submitFormData}>
                Submit
            </button>
            </div>
          </div>
        )}
    </>
  );
}
