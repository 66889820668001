import React from "react";
import bannerImage from "../assets/bannerImage.png";

export default function RegisterBanner() {
  return (
    <>
      <div className="bannerHolder">
        <div className="bannerContent">
          <h1> User Onboarding Form</h1>

          <p>This form is to capture user and device information for a lab.</p>
        </div>
        <div className="bannerImage">
          <img src={bannerImage} alt="banner" className="bannerRight" />
        </div>
      </div>
    </>
  );
}
