import React from "react";
import logo from "../assets/lab1.jpg";
import logouts from "../assets/logout.png";
import { useHistory } from "react-router-dom";
import footerlogo from "../assets/applogo.png";
import footerlogoapp from "../assets/apptext.png";

export default function Header() {
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("cookie");

    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  React.useEffect(() => {
    if (localStorage.getItem("cookie") !== "superadmin") {
      history.push("/");
    }
  }, []);

  return (
    <>
      <div className="headerHolder">
        <div className="lefImage">
          <span>
            <img className="footerimgadj widthLog" src={footerlogo} />
          </span>
          <span>
            <img className="footerimgadj" src={footerlogoapp} />
          </span>
        </div>

        <div className="rightImage">
          <img src={logouts} onClick={logout} />
        </div>
      </div>
    </>
  );
}
